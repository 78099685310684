<template>
    <div>
        <span class="titulosg h4">Usuarios Registrados</span>

        <div class="bg-white p-4 mt-3">
            <div class="mb-3">
                Registros encontrados <span class="badge bg-primary">{{personas.length}}</span>
            </div>
            <div class="mb-3">
                <form @submit.prevent="buscar" >
                    <input v-model="palabrabuscar" type="text" required class="form-control form-control-input" placeholder="Buscar">
                </form>
            </div>
            
            <div style="height: 350px; overflow-y: auto;" class="border mt-2">
                <table class="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">N°</th>
                            <th class="text-center">SINC</th>
                            <th>Identificación</th>
                            <th>Nombre de la Persona</th>
                            <th>Usuario</th>
                            <th>Nombre de Usuario</th>
                            <th>Creado</th>
                            <th>Actualizado</th>
                            <th>Estado</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in personas" :key="index">   
                            <td class="text-center">{{index+1}}</td>
                            <td class="text-center"> 
                                <span class="text-danger" v-if=" item.idpersona != item.idpersonas">
                                    <i class="fas fa-exclamation-triangle fa-lg"></i>
                                </span>
                            </td>
                            <td> {{item.identificacion}} </td>
                            <td> {{item.nombres}} {{item.apellidos}} </td>
                            <td> <strong>{{item.email}}</strong> </td>
                            <td> {{item.name}} </td>
                            <td> {{ FormatoFecha(item.created_at)}} </td>
                            <td>  {{ FormatoFecha(item.updated_at)}} </td>
                            <td> 
                                <span v-if="item.estado == 1" class="p-1 bg-success text-success">Activo</span>
                                <span v-if="item.estado == 0" class="p-1 bg-danger text-white">Inactivo</span>
                            </td>
                            <td> 
                                <button class="btn btn-sm btn-warning" v-if="item.idpersona != item.idpersonas" @click.prevent="CrearUsuario(item.idpersonas, item.nombres+' '+item.apellidos,item.identificacion)"> <i class="fas fa-plus"></i> </button>   
                                <button class="btn btn-sm btn-success" v-if="item.idpersona == item.idpersonas"> <i class="fas fa-pencil-alt"></i> </button> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'

export default {
    setup() {
        
        const personas = ref([])
        const palabrabuscar = ref('')

        const consultarusuarios = () => {
            axios.get('/api/consultarusuarios').then( response => {
                personas.value = response.data
            })
        }     

        const buscar = () => {
            personas.value = personas.find( item => item.nombres === palabrabuscar.value );
        }
        
        const FormatoFecha = (date) => {
            return moment(date).format(' D[-]MMM[-]YYYY');
        }

        const CrearUsuario = (idpersona,nombre,identificacion) => {
            axios.get('/api/crearusuario/'+idpersona+'/'+nombre+'/'+identificacion).then( response => {
                console.log(response.data)
                swal("Ok!", "Usuario registrado correctamente!", "success");  
                consultarusuarios()
            })
        }


        onMounted(() => {
          consultarusuarios()
        })

        return {

            personas,
            palabrabuscar,
            FormatoFecha,
            buscar,
            CrearUsuario

        }

    }
}
</script>

<style>

</style>
